import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fdfecf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "doc-id" }
const _hoisted_2 = { class: "doc-meta" }
const _hoisted_3 = { class: "scanner" }
const _hoisted_4 = ["data-product-id"]
const _hoisted_5 = { class: "product-info" }
const _hoisted_6 = { class: "location" }
const _hoisted_7 = { class: "product-count" }
const _hoisted_8 = { slot: "end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/returns",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Return Details")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.current.shopifyOrderName ? _ctx.current.shopifyOrderName : _ctx.current.hcOrderId ? _ctx.current.hcOrderId : _ctx.current.externalId ? _ctx.current.externalId : _ctx.translate("Return Details")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ion_badge, {
                  color: _ctx.statusColorMapping[_ctx.current.statusDesc],
                  slot: "end"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.current.statusDesc), 1)
                  ]),
                  _: 1
                }, 8, ["color"]),
                (_ctx.current.trackingCode)
                  ? (_openBlock(), _createBlock(_component_ion_chip, {
                      key: 0,
                      slot: "end"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.current.trackingCode), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    label: _ctx.translate(_ctx.isReturnReceivable(_ctx.current.statusId) ? 'Scan items' : 'Search items'),
                    autofocus: "",
                    modelValue: _ctx.queryString,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryString) = $event)),
                    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.isReturnReceivable(_ctx.current.statusId) ? _ctx.updateProductCount() : _ctx.searchProduct()), ["enter"]))
                  }, null, 8, ["label", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                expand: "block",
                fill: "outline",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scanCode())),
                disabled: !_ctx.isReturnReceivable(_ctx.current.statusId)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.barcodeOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(_toDisplayString(_ctx.translate("Scan")), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.current.items, (item) => {
              return (_openBlock(), _createBlock(_component_ion_card, {
                key: item.id,
                class: _normalizeClass(_ctx.getProductIdentificationValue(_ctx.barcodeIdentifier, _ctx.getProduct(item.productId)) === _ctx.lastScannedId ? 'scanned-item' : ''),
                id: _ctx.getProductIdentificationValue(_ctx.barcodeIdentifier, _ctx.getProduct(item.productId))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "product",
                    "data-product-id": item.productId
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_ion_item, { lines: "none" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_thumbnail, {
                            slot: "start",
                            onClick: ($event: any) => (_ctx.openImage(_ctx.getProduct(item.productId).mainImageUrl, _ctx.getProduct(item.productId).productName))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_DxpShopifyImg, {
                                src: _ctx.getProduct(item.productId).mainImageUrl
                              }, null, 8, ["src"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName), 1),
                              _createElementVNode("p", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      (_ctx.productQoh[item.productId] === '' || !(_ctx.productQoh[item.productId] >= 0))
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 0,
                            fill: "clear",
                            onClick: _withModifiers(($event: any) => (_ctx.fetchQuantityOnHand(item.productId)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                color: "medium",
                                slot: "icon-only",
                                icon: _ctx.cubeOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 1,
                            outline: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("on hand", { qoh: _ctx.productQoh[item.productId] })) + " ", 1),
                              _createVNode(_component_ion_icon, {
                                color: "medium",
                                icon: _ctx.cubeOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1024))
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      (_ctx.isReturnReceivable(_ctx.current.statusId) && item.quantityReceived === 0)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                label: _ctx.translate('Qty'),
                                disabled: _ctx.isForceScanEnabled,
                                "label-placement": "floating",
                                type: "number",
                                min: "0",
                                modelValue: item.quantityAccepted,
                                "onUpdate:modelValue": ($event: any) => ((item.quantityAccepted) = $event)
                              }, null, 8, ["label", "disabled", "modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024))
                        : (_openBlock(), _createBlock(_component_ion_item, {
                            key: 1,
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.quantityAccepted) + " " + _toDisplayString(_ctx.translate("received")), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                    ])
                  ], 8, _hoisted_4),
                  (item.quantityOrdered > 0)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 0,
                        lines: "none",
                        class: "border-top"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.isReturnReceivable(_ctx.current.statusId) && item.quantityReceived === 0)
                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                key: 0,
                                disabled: _ctx.isForceScanEnabled,
                                onClick: ($event: any) => (_ctx.receiveAll(item)),
                                slot: "start",
                                fill: "outline"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate("Receive All")), 1)
                                ]),
                                _: 2
                              }, 1032, ["disabled", "onClick"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_progress_bar, {
                            color: _ctx.getRcvdToOrdrdFraction(item) === 1 ? 'success' : _ctx.getRcvdToOrdrdFraction(item) > 1 ? 'danger' : 'primary',
                            value: _ctx.getRcvdToOrdrdFraction(item)
                          }, null, 8, ["color", "value"]),
                          _createElementVNode("p", _hoisted_8, _toDisplayString(item.quantityOrdered) + " " + _toDisplayString(_ctx.translate("returned")), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["class", "id"]))
            }), 128))
          ]),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              (_ctx.isReturnReceivable(_ctx.current.statusId))
                ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                    key: 0,
                    disabled: !_ctx.hasPermission(_ctx.Actions.APP_SHIPMENT_UPDATE) || !_ctx.isEligibleForReceivingReturns(),
                    onClick: _ctx.completeShipment
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.checkmarkDone }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}